import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import PeopleIcon from '@material-ui/icons/People';
import React, { Fragment, useCallback, useState } from 'react';
import {
  BooleanInput,
  Button,
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

const useStyles = makeStyles({
  button: {
    justifyContent: 'center',
    backgroundColor: '#FB6E00',
    width: '212px',
    color: 'white',
    height: '48px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#FB6E00',
    },
  },
});

export const PostQuickCreateButton = (props) => {
  const classes = useStyles();
  const [showDialog, setshowDialog] = useState(false);
  const handleClick = useCallback(() => {
    setshowDialog(true);
  }, [showDialog]);

  const handleCloseClick = useCallback(() => {
    setshowDialog(false);
  }, [showDialog]);

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        className={classes.button}
        label="ra.action.create"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create user"
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" alignContent="center">
            Create user
            {<PeopleIcon style={{ color: 'orange', paddingLeft: '10px' }} />}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Create resource="user" basePath="/" {...props}>
            <SimpleForm>
              <TextInput source="email" />
              <BooleanInput label="Admin" source="admin" />
            </SimpleForm>
          </Create>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default PostQuickCreateButton;

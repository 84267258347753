/* eslint-disable no-unused-vars */

import { Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import {
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  FilterList,
  FilterListItem,
  FunctionField,
  List,
  ShowButton,
  TextField,
  useNotify,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Loading } from 'src/loading/Loading';
import myDataProvider from 'src/MyDataProvider';
import { PostPagination } from 'src/pagination/Pagination';

const useStyles = makeStyles({
  CLOSED: {
    backgroundColor: '#ED25251A',
    color: '#ED2525',
  },
  UPDATED: {
    backgroundColor: '#2581ED1A',
    color: '#2581ED',
  },
  button: {
    justifyContent: 'center',
    backgroundColor: '#FB6E00',
    width: '212px',
    color: 'white',
    height: '48px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#FB6E00',
    },
  },
  text: {
    fontFamily: 'Mulish',
    paddingLeft: '16px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0px',
    marginRight: '5px',
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  textField: {
    '&::placeholder': {
      paddingLeft: '10px',
    },
    height: '48px',
    width: '500px',
    borderRadius: '4px',
    paddingBottom: 0,
    fontWeight: 500,
    border: 'none',
    color: '#8B94A1',
  },
});

const FilterSidebar = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <HasOrderedFilter />
  </Box>
);

const HasOrderedFilter = () => {
  const classes = useStyles();
  return (
    <FilterList className={classes.text} label="">
      <FilterListItem label="Received" value={{ id: 'RECEIVED' }} />
      <FilterListItem label="Updated" value={{ id: 'UPDATED' }} />
      <FilterListItem label="Closed" value={{ id: 'CLOSED' }} />
    </FilterList>
  );
};

const ManifestList = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const ColoredChipField = ({ record }) => (
    <ChipField
      source="status"
      label="Status"
      className={
        // eslint-disable-next-line no-nested-ternary
        record.status === 'CLOSED'
          ? classes.CLOSED
          : record.status === 'RECEIVED'
            ? classes.RECEIVED
            : classes.UPDATED
      }
    />
  );

  const handleClick = async (e) => {
    e.preventDefault();
    const awb = { id: e.target.children[0].value };
    setLoading(true);
    const resp = await myDataProvider.getOne('manifest/awb', awb);
    if (!resp.data) {
      notify('there is not a manifest with that awb','error');
      return setLoading(false);
    }
    history.push(`/manifest/${resp.data.id}/show`);
  };

  const CommentShowButton = ({ record }) => (
    <ShowButton basePath="/manifest" label="Show manifest" record={record} />
  );

  const errorMessage = useSelector(
    (data) => data.admin.resources.manifest.list.ids,
  );
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '10px 0px 10px 0px',
        }}
      >
        <Box
          style={{ width: '97%' }}
          justifyContent="space-between"
          display="flex"
        >
          <div className={classes.searchInput}>
            <form onSubmit={handleClick}>
              <input
                className={classes.textField}
                source="id"
                placeholder="Awb"
                resettable
                alwaysOn
              />
            </form>
            <SearchIcon
              style={{
                position: 'absolute',
                color: '#FB6E00',
                paddingRight: '10px',
              }}
            />
          </div>
          <CreateButton className={classes.button} basePath={props.basePath} />
        </Box>
      </div>
      <List
        {...props}
        pagination={<PostPagination />}
        aside={<FilterSidebar />}
        actions={false}
        exporter={false}
      >
        {errorMessage.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ paddingBottom: '20px' }}
          >
            <p className={classes.text}>
              There are no manifests with that state
            </p>
            <ErrorOutlineIcon style={{ color: 'red' }} />
          </Box>
        ) : (
          <Datagrid>
            <TextField sortable={false} label="AWB" source="awb" />
            <TextField
              sortable={false}
              source="manifestNumber"
              label="MANIFEST NUMBER"
            />
            <FunctionField
              label="MANIFEST STATUS"
              render={(record) => <ColoredChipField record={record} />}
            />
            <TextField
              sortable={false}
              label="TOTAL ITEMS"
              source="totalItems"
            />
            <TextField sortable={false} label="AIRLINES" source="airlines" />
            <DateField label="DATE" source={'arrivalTime' || 'createdAt'} />
            <CommentShowButton label="ACTION" />
          </Datagrid>
        )}
      </List>
      {loading ? <Loading></Loading> : null}
    </>
  );
};

export default ManifestList;

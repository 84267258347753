import {
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';

export const Loading = () => {
  const [showDialog] = useState(true);

  return (
    <Dialog
      fullWidth
      open={showDialog}
      aria-label="Edit user"
    >
      <DialogTitle>Loading...</DialogTitle>
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
};

export default Loading;

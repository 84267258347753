import simpleRestProvider from 'ra-data-simple-rest';

import { httpClient } from './httpClient/HttpClient';

const servicesHost = process.env.REACT_APP_API_URL;

const dataProvider = simpleRestProvider(servicesHost, httpClient);

const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    if (resource !== 'manifest') {
      return dataProvider.create(resource, params);
    }
    if (params.data.files.rawFile) {
      const formData = new FormData();
      formData.append('file', params.data.files.rawFile);
      if (params.data.date) {
        formData.append('date', params.data.date);
      }
      return httpClient(`${servicesHost}/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }
  },
  update: (resource, params) => {
    if (resource !== 'manifest') {
      return dataProvider.update(resource, params);
    }
    if (params.data.files.rawFile) {
      const formData = new FormData();
      formData.append('file', params.data.files.rawFile);

      return httpClient(`${servicesHost}/${resource}/${params.id}`, {
        method: 'PUT',
        body: formData,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }
    const formData = new FormData();
    formData.append('file', params);

    return httpClient(`${servicesHost}/${resource}/${params.id}`, {
      method: 'PUT',
      body: formData,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
};

export default myDataProvider;

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BookIcon from '@material-ui/icons/Book';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  useNotify,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BackButton from 'src/backbutton/BackButton';
import { Loading } from 'src/loading/Loading';
import myDataProvider from 'src/MyDataProvider';

const useStyles = makeStyles({
  divFlex: {
    marginBottom: '1vh',
  },
  divFlex2: {
    width: '97%',
  },
  textField: {
    '&::placeholder': {
      paddingLeft: '10px',
    },
    height: '48px',
    width: '500px',
    borderRadius: '4px',
    paddingBottom: 0,
    marginTop: '10px',
    fontWeight: 500,
    border: 'none',
    color: '#8B94A1',
  },
  button: {
    justifyContent: 'center',
    backgroundColor: '#FB6E00',
    width: '350px',
    color: 'white',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: 'white',
      color: '#FB6E00',
    },
  },
  boxdiV: {
    width: '100%',
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '18%',
    minWidth: '200px',
    height: '75px',
    border: ' 1px solid #DEDEE0',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '10px',
    marginBottom: '10px',
  },
  text: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    paddingLeft: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0px',
    textAlign: 'left',
    margin: '0px',
    color: '#6E7580',
  },
  bottomText: {
    fontFamily: 'Mulish',
    paddingLeft: '16px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0px',
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  CLOSED: {
    backgroundColor: '#ED25251A',
    color: '#ED2525',
  },
  RELEASED: {
    backgroundColor: '#9f9',
    color: '#008200',
  },
});

const CommentEditButton = ({ record }) => (
  <EditButton
    basePath="/shipments"
    label="Edit"
    resource="shipments"
    record={record}
  />
);

export const ManifestShow = (props) => {
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyles();
  const handleClick = async (e) => {
    e.preventDefault();
    const resp = await myDataProvider.getOne(
      `manifest/${e.target.children[0].value}`,
      props,
    );
    if (!resp.data) {
      return notify(
        'there is not a shipment with that tracking number',
        'error',
      );
    }
    history.push(`/shipments/${resp.data.id}`);
  };

  const handleRedirect = async () => {
    history.push(`${props.basePath}/${props.id}`);
  };

  const ColoredChipField = ({ record }) => (
    <ChipField
      source="status"
      label="Status"
      className={
        // eslint-disable-next-line no-nested-ternary
        record.status === 'RETAINED'
          ? classes.CLOSED
          : record.status === 'RECEIVED'
            ? classes.RECEIVED
            : classes.RELEASED
      }
    />
  );
  const loading = useSelector((data) => data.admin.loading);
  return (
    <>
      <div style={{ width: '97%', paddingLeft: '1.5%' }}>
        <BackButton></BackButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          style={{ width: '97%' }}
          justifyContent="space-between"
          display="flex"
        >
          <div className={classes.searchInput}>
            <form onSubmit={handleClick}>
              <input
                className={classes.textField}
                source="id"
                placeholder="Tracking number"
                resettable
                alwaysOn
              />
            </form>
            <SearchIcon
              style={{
                position: 'absolute',
                color: '#FB6E00',
                paddingRight: '10px',
                paddingTop: '10px',
              }}
            />
          </div>
          <EditButton
            basePath="/manifest"
            label="Upload second manifest"
            resource="manifest"
            onClick={handleRedirect}
            className={classes.button}
          />
        </Box>
      </div>
      <Box justifyContent="center" display="flex">
        <Show className={classes.divFlex2} actions={false} {...props}>
          <>
            <SimpleShowLayout className={classes.divFlex}>
              <div className={classes.divFlex}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <div className={classes.box}>
                    <p className={classes.text}>Manifest number</p>
                    <TextField
                      source="manifestNumber"
                      label="Manifest number"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Awb</p>
                    <TextField
                      source="awb"
                      label="Awb"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Status</p>
                    <TextField
                      label="Status"
                      source="status"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Total items</p>
                    <TextField
                      label="Total items"
                      source="totalItems"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Airline</p>
                    <TextField
                      label="Airline"
                      source="airlines"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Destination country</p>
                    <TextField
                      label="Destination country"
                      source="destinationCountry"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Created</p>
                    <DateField
                      label="Created"
                      source={'arrivalTime'}
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Updated</p>
                    <DateField
                      label="Updated"
                      source="updatedAt"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Total weight</p>
                    <TextField
                      label="Total weight (kg)"
                      source="totalWeight"
                      className={classes.bottomText}
                    />
                  </div>
                  <div className={classes.box}>
                    <p className={classes.text}>Shipper</p>
                    <TextField
                      label="Total weight (kg)"
                      source="shipper"
                      className={classes.bottomText}
                    />
                  </div>
                </Box>
              </div>
            </SimpleShowLayout>
            <ArrayField source="shipments">
              <Datagrid>
                <TextField
                  sortable={false}
                  source="trackingNumber"
                  label="TRACKING NUMBER"
                />
                <TextField sortable={false} source="dni" label="DNI" />
                <TextField sortable={false} source="weight" label="WEIGHT" />
                <TextField sortable={false} source="value" label="VALUE" />
                <TextField sortable={false} source="user" label="USER" />
                <TextField sortable={false} source="taxes" label="TAXES" />
                <FunctionField
                  label="SKYPOSTAL STATUS"
                  render={(record) => <ColoredChipField record={record} />}
                />
                <CommentEditButton label="ACTION" />
                <FunctionField
                  label="INVOICE"
                  render={(record) => (
                    <BookIcon
                      style={{ color: 'rgb(245, 124, 0)' }}
                      record={record}
                      onClick={() => {
                        window.open(`${record.originalInvoices}`);
                      }}
                    />
                  )}
                />
              </Datagrid>
            </ArrayField>
          </>
        </Show>
      </Box>
      {loading ? <Loading /> : null}
    </>
  );
};

import Button from '@material-ui/core/Button';
import React from 'react';
import { withRouter } from 'react-router';

const BackButton = ({ history: { goBack }, children, ...props }) => (
  <Button
    style={{
      backgroundColor: '#f57c00',
      color: 'white',
      padding: '5px 20px 5px 20px',
      marginTop: '10px',
      marginBottom: '10px',
      width: ' 100px',
    }}
    {...props}
    onClick={goBack}
  >
    back
  </Button>
);

export default withRouter(BackButton);

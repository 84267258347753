import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  Edit,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';
import BackButton from 'src/backbutton/BackButton';

const useStyles = makeStyles({
  divFlex: {
    width: '37%',
  },
  simpleShow: {
    backgroundColor: 'transparent',
  },
  divFlex2: {
    width: '60%',
    marginTop: '16px',
  },
  divFlex3: {
    width: '100%',
  },
  box: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '40%',
    height: '50px',
    border: ' 1px solid #DEDEE0',
    marginBottom: '15px',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  text: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    letterSpacing: '0px',
    textAlign: 'left',
    margin: '0px',
    color: '#6E7580',
  },
  bottomText: {
    fontFamily: 'Mulish',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '0px',
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  input: {
    width: '90%',
    borderRadius: '4px',
  },
  label: {
    margin: '0px',
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    color: '#5F6162',
  },
});

export const ShipmentsEditModal = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState(true);

  const handleChange = (e) => {
    setState(e.target.value);
  };

  React.useEffect(() => {
    setState(state);
  }, [state]);
  return (
    <>
      <div style={{ paddingLeft: '2%' }}>
        <BackButton></BackButton>
      </div>
      <Box
        style={{ width: '100%' }}
        justifyContent="space-around"
        display="flex"
      >
        <Show {...props} actions={false} className={classes.divFlex}>
          <SimpleShowLayout className={classes.simpleShow} redirect={false}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              <div className={classes.box}>
                <p className={classes.text}>Tracking number</p>
                <TextField
                  sortable={false}
                  source="trackingNumber"
                  label="Tracking number"
                  className={classes.bottomText}
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Local tacking provider</p>
                <TextField
                  sortable={false}
                  source="localTrackingProvider"
                  label="Local tacking provider"
                  className={classes.bottomText}
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Dni</p>
                <TextField
                  sortable={false}
                  source="dni"
                  label="Dni"
                  className={classes.bottomText}
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>User</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="user"
                  label="User"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Weight</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="weight"
                  label="Weight"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Value</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="value"
                  label="Value"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Phone</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="phone"
                  label="Phone"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Taxes</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="taxes"
                  label="Taxes"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Description</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="description"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Status</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="status"
                  label="Status"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Retencion reason</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="retencionReason"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Extra comments</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="extraComments"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Invoice</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="invoice"
                />
              </div>
              <div className={classes.box}>
                <p className={classes.text}>Skypostal error</p>
                <TextField
                  sortable={false}
                  className={classes.bottomText}
                  source="skypostalError"
                />
              </div>
            </Box>
          </SimpleShowLayout>
        </Show>
        <Edit
          className={classes.divFlex2}
          resource="shipments"
          basePath={props.basePath}
          {...props}
        >
          <SimpleForm
            toolbar={
              <Toolbar>
                <SaveButton />
              </Toolbar>
            }
            style={{ padding: '20px', backgroundColor: 'white' }}
            redirect={false}
          >
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-evenly">
                <Box
                  style={{ width: '100%' }}
                  display="flex"
                  flexDirection="column"
                >
                  <p className={classes.label}>Taxes</p>
                  <TextInput
                    className={classes.input}
                    type="number"
                    source="taxes"
                  />
                  {state === 'RETAINED' ? (
                    <TextInput className={classes.input} source="Comments" />
                  ) : null}
                </Box>
                <Box
                  style={{ width: '100%' }}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <p className={classes.label}>New Status</p>
                  <SelectInput
                    className={classes.input}
                    source="newStatus"
                    allowEmpty
                    onChange={handleChange}
                    choices={[
                      { id: 'RECEIVED', name: 'Received' },
                      { id: 'RETAINED', name: 'Retained' },
                      { id: 'RELEASED', name: 'Released' },
                    ]}
                  />
                  {state === 'RETAINED' ? (
                    <>
                      <SelectInput
                        className={classes.input}
                        source="Reason"
                        allowEmpty
                        choices={[
                          { id: 'PROHIBITED', name: 'Prohibited product' },
                          { id: 'PENDING_TAXES', name: 'Pending taxes' },
                          {
                            id: 'DOCUMENTATION_LACK',
                            name: 'Lack of documentation',
                          },
                        ]}
                      />
                    </>
                  ) : null}
                </Box>
              </Box>
            </Box>
            {state === 'RELEASED' ? <TextInput source="Invoice" /> : null}
          </SimpleForm>
        </Edit>
      </Box>
    </>
  );
};

export default ShipmentsEditModal;

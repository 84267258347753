import React from 'react';
import { Admin, Resource } from 'react-admin';

import MyLayout from './appBar/MyLayout';
import auth from './auth';
import { Dashboard } from './dashboard/Dashboard';
import i18nProvider from './localization';
import Manifest from './manifest';
import myDataProvider from './MyDataProvider';
import { ProfileEdit } from './profile/ProfileEdit';
import { ShipmentsEditModal } from './shipments/ShipmentsEditModal';
import theme from './theme';
import User from './users';

const App = () => 

  (
    <Admin
      layout={MyLayout}
      theme={theme}
      authProvider={auth}
      dataProvider={myDataProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
    >
      <Resource name="user" {...User} />
      <Resource name="manifest" {...Manifest} />
      <Resource name="profile" edit={ProfileEdit} />
      <Resource name="shipments" edit={ShipmentsEditModal} />
    </Admin>
  )
;

export default App;

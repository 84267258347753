import React from 'react';
import { TopToolbar } from 'react-admin';

import { UserEditModal } from './UserEditModal';

export const UserEdit = (props) => (
  <TopToolbar>
    <UserEditModal {...props} />
  </TopToolbar>
);

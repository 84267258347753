import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconCancel from '@material-ui/icons/Cancel';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const ProfileEdit = (props) => {
  const history = useHistory();
  const [showDialog, setshowDialog] = useState(true);

  const handleCloseClick = () => {
    setshowDialog(false);
  };

  useEffect(() => {
    if (!showDialog) {
      history.goBack();
    }
  }, [showDialog]);
  return (
    <Fragment>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit user"
      >
        <DialogTitle>Edit profile</DialogTitle>
        <DialogContent>
          <Edit resource="user" basePath={props.basePath} {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
              <TextInput source="oldPassword" />
              <TextInput source="newPassword" />
            </SimpleForm>
          </Edit>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ProfileEdit;

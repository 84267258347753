import { Box, Button, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useListContext } from 'react-admin';

const useStyles = makeStyles({
  currentPage: {
    backgroundColor: '#FB6E00',
    color: 'white',
    borderRadius: '4px',
    padding: '6px 12px 6px 12px',
    height: '24px',
  },
  sidePages: {
    marginRight: '15px',
    marginLeft: '15px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '21px',
    letterSpacing: '0.10000000149011612px',
    textAlign: 'center',
  },
});

export const PostPagination = () => {
  const classes = useStyles();
  const { page, perPage, total, setPage } = useListContext();
  const nbPages = Math.ceil(total / perPage) || 1;

  const displayPage = () => {
    if (total < 10) {
      return (
        <p>
          {page}-{total}
        </p>
      );
    }

    if (page === 1) {
      return (
        <p>
          {page}-{perPage}
        </p>
      );
    }

    if (page * perPage >= total) {
      return (
        <p>
          {(page - 1) * perPage}-{total}
        </p>
      );
    }
    return (
      <p>
        {(page - 1) * perPage}-{page * perPage}
      </p>
    );
  };

  return (
    <Toolbar style={{ marginTop: '20px' }}>
      <Box
        style={{
          backgroundColor: 'white',
          padding: '10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {displayPage()}

        {page > 1 && (
          <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
            <ChevronLeft />
          </Button>
        )}
        <div className={classes.sidePages}>{page > 1 && page - 1}</div>
        <div className={classes.currentPage}>{page}</div>
        <div className={classes.sidePages}>{page !== nbPages && page + 1}</div>
        {page !== nbPages && (
          <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
            <ChevronRight />
          </Button>
        )}
      </Box>
    </Toolbar>
  );
};

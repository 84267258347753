const auth = {
  login: async ({ username, password }) => {
    try {
      let resp = await fetch(`${process.env.REACT_APP_API_URL}/auth/signin`, {
        method: 'POST',
        body: JSON.stringify({ email: username, password }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Expose-Headers': 'Content-Range',
        },
      });
      resp = await resp.json();
      if (resp.accesToken) {
        localStorage.setItem('auth', JSON.stringify(resp.accesToken));
        localStorage.setItem('role', resp.permissions ? 'admin' : 'user');
        localStorage.setItem('userId', resp.id);
        localStorage.setItem('username', username);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject();
    }
  },

  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),

  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default auth;

import React from 'react';
import { TopToolbar } from 'react-admin';

import { PostQuickCreateButton } from './UserPostButton';

export const PostReferenceInput = () => (
  <TopToolbar>
    <PostQuickCreateButton />
  </TopToolbar>
);

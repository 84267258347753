import { Box, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BookIcon from '@material-ui/icons/Book';
import PeopleIcon from '@material-ui/icons/People';
import useLocalStorage from '@rehooks/local-storage';
import React from 'react';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  textField: {
    fontWeight: 1000
  },
  title: {
    fontWeight: 700,
    fontSize:'25px',
    marginBottom:'10px'
  },
  avatar: {
    height: 100,
    width: 100,
    color: 'black',
    backgroundColor: 'transparent',
    marginRight: '0.5vw',
  },
});

export const Dashboard = () => {
  const [role] = useLocalStorage('role');
  const classes = useStyles();
  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        flexDirection="column"
      >
        <CardContent className={classes.title}>Welcome to the Skypostal administrator</CardContent>
      </Box>
      {role === 'admin' ? (
        <Box display="flex" justifyContent="space-around">
          <Box display="flex" flexDirection="column" alignItems="center">
            <CardContent className={classes.textField}>
              If you want to see and handle users
            </CardContent>
            <Link to="/user">
              <PeopleIcon className={classes.avatar} />
            </Link>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CardContent className={classes.textField}>
              If you want to upload or handle manifests
            </CardContent>
            <Link to="/manifest">
              <BookIcon className={classes.avatar} />
            </Link>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <CardContent className={classes.textField}>
            If you want to upload or handle manifests
          </CardContent>
          <BookIcon className={classes.avatar} />
        </Box>
      )}
    </Card>
  );
};

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconCancel from '@material-ui/icons/Cancel';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Edit, SimpleForm, TextField, TextInput } from 'react-admin';
import { useHistory } from 'react-router-dom';

export const UserEditModal = (props) => {
  const history = useHistory();
  const [showDialog, setshowDialog] = useState(true);

  const handleCloseClick = useCallback(() => {
    setshowDialog(false);
  }, [showDialog]);

  useEffect(() => {
    if (!showDialog) {
      history.push('/user');
    }
  }, [showDialog]);
  return (
    <Fragment>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Edit user"
      >
        <DialogTitle>Edit user</DialogTitle>
        <DialogContent>
          <Edit resource="user" basePath="/" {...props}>
            <SimpleForm>
              <TextField source="email" />
              <TextInput source="password" />
            </SimpleForm>
          </Edit>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default UserEditModal;

import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import {
  Create,
  DateTimeInput,
  FileField,
  FileInput,
  SimpleForm,
} from 'react-admin';

const useStyles = makeStyles({
  input: {
    width: '20%',
    borderRadius: '4px',
  },
});

export const ManifestPost = (props) => {
  // const [date, setDate] = React.useState();
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm
        style={{ padding: '20px', backgroundColor: 'white' }}
        redirect="show"
      >
        <FileInput
          id="file"
          source="files"
          placeholder={<p>Drop your file here</p>}
        >
          <FileField />
        </FileInput>

        <DateTimeInput className={classes.input} source="date"></DateTimeInput>
      </SimpleForm>
    </Create>
  );
};

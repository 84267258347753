import React from 'react';
import {
  Edit,
  FileField,
  FileInput,
  SimpleForm,
} from 'react-admin';

const ManifestEdit = (props) => (
  <Edit {...props}>
    <SimpleForm
      style={{ padding: '20px', backgroundColor: 'white' }}
      redirect="show"
    >
      <FileInput
        id="file"
        source="files"
        placeholder={<p>Drop your file here</p>}
      >
        <FileField />
      </FileInput>
    </SimpleForm>
  </Edit>
);
export default ManifestEdit;

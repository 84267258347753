import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import useLocalStorage from '@rehooks/local-storage';
import * as React from 'react';
import { AppBar, Link } from 'react-admin';

const useStyles = makeStyles({
  navBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  avatar: {
    height: 25,
    width: 25,
    color: '#8B94A1',
    backgroundColor: 'transparent',
    marginRight: '0.5vw',
  },
});

const MyAppBar = (props) => {
  const [id] = useLocalStorage('userId');
  const classes = useStyles();
  return (
    <AppBar style={{ background: 'white' }} {...props}>
      <div className={classes.navBar}>
        <Link to={`/profile/${id}`}>
          <Avatar className={classes.avatar}>
            <LockIcon></LockIcon>
          </Avatar>
        </Link>
      </div>
    </AppBar>
  );
};

export default MyAppBar;

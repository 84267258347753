import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';

const myTheme = merge({}, defaultTheme, {
  transitions: {
    easeIn: 'cubic-bezier(2, 0, 1, 3)',
  },
  palette: {
    primary: orange,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: 'Mulish',
  },
  overrides: {
    RaDatagrid: {
      headerCell: {
        color: 'black',
        height: '20px',
        fontWeight: '700',
        fontSize: '14px',
        Lineheight: '21px',
        fontFamily: 'Mulish',
      },
      rowCell: {
        color: '#6E7580',
        backgroundColor: 'white',
        fontWeight: '400',
        fontSize: '14px',
        Lineheight: '21px',
        paddingTop: '12px ',
        paddingBottom: '12px',
      },
      headerRow: {
        padding: '20px',
      },
    },
    RaCardContentInner:{
      root:{
        paddingTop: '0px',
      }
    },
    RaSidebar: {
      root: {
        borderRight: '#E8EAF0',
        borderRightWidth: '2px',
        borderRightStyle: 'solid',
      },
    },
    RaCardHeader: {
      title: {
        fontWeight: 'bold',
        fontSize: '23px',
      },
    },
    RaMenuItemLink: {
      root: {
        marginTop: '2vh',
        fontWeight: '700',
        fontSize: '18px',
      },
      active: {
        color: '#FB6E00',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0px',
      },
    },
    RaDeleteWithUndoButton: {
      deleteButton: {
        width: '160px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        backgroundColor: '#F6F7FB',
      },
    },
    MuiDialogActions: {
      root: { paddingRight: '20px' },
    },
    RaCardContent: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    RaFormInput: {
      input: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      },
    },
    RaLoadingIndicator: {
      loadedIcon: {
        color: '#8B94A1',
      },
    },
    MuiButtonBase: {
      root: {
        color: '#8B94A1',
      },
    },
    MuiCardContent: {
      root: {
        padding: '0px',
      },
    },
    RaToolbar: {
      desktopToolbar: {
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0px',
        marginTop: '0px',
      },
      defaultToolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    RaSaveButton: {
      button: {
        height: '48px',
        width: '160px',
        borderRadius: '4px',
        color: 'white',
      },
    },
    RaBulkActionsToolbar:{
      topToolbar:{
        paddingRight:'20px'
      }
    },
    RaLayout: {
      content: {
        backgroundColor: '#F6F7FB',
        display: 'flex',
        justifyContent: 'flex-start',
        alignContent: 'center',
        paddingRight: '5px',
      },
    },
    RaShow: {
      card: {
        shadow: 'none',
        boxShadow: 'none',
        paddingTop: '0px',
        backgroundColor: 'transparent',
      },
      noActions: {
        marginTop: '0px',
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: '0px',
      },
    },
    RaUserMenu: {
      user: {
        color: '#8B94A1',
      },
    },
    RaEdit: {
      card: {
        padding: '0px 20px 0px 20px',
      },
      noActions: {
        marginTop: '0px',
      },
    },
    RaCreate: {
      root: {
        padding: '0px 25.5px 0px 25.5px',
      },
      noActions: {
        marginTop: '0px',
      },
    },
    RaListToolbar: {
      toolbar: {
        width: '97%',
        paddingBottom: '10px',
      },
      actions: {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
    RaTopToolbar: {
      root: {
        paddingTop: '10px',
        width: ' 98%',
      },
    },
    RaList: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      main: {
        width: '97%',
        justifyContent: 'center',
      },
    },
    MuiTable: {
      root: {
        backgroundColor: 'white',
        padding: '40px 40px 40px 40px',
        borderCollapse: 'none',
      },
    },
    MuiToolbar: {
      regular: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '0px',
      },
    },
    RaAppBar: {
      menuButton: {
        color: '#110A5B',
      },
    },
  },
});

export default myTheme;

import ManifestList from './Manifest_List';
import { ManifestPost } from './Manifest_Post';
import { ManifestShow } from './Manifest_Show';
import ManifestEdit from './ManifestEdit';

export default {
  create: ManifestPost,
  list: ManifestList,
  show: ManifestShow,
  edit: ManifestEdit,
};
